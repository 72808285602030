export enum ERenewStatus {
    "",
    NO,
    FAIL,
    REGISTER,
    DONE
}

export enum ERenewStatusDesc {
    "",
    "无需续费",
    "续费失败",
    "续费登记",
    "已登记"
}

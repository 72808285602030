import { service } from '@/ajax/request'


export const deviceList = (params: any) => (
    service({
        url: '/QiaoDevice/getActivateList',
        method: 'GET',
        params,
        type: 'newForm'
    })
)

export const queryService = (params) => {
    return service({
        url: '/QiaoDevice/query_service',
        method: 'POST',
        params,
    })
}



export const activateDevice = (params: any) => (
    service({
        url: '/QiaoDevice/activate/batch',
        method: 'POST',
        type: 'form',
        params
    })
)

export const settlement = (params: any) => {
    return service({
        url: '/QiaoDevice/settlement',
        method: 'POST',
        params
    })
}